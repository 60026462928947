<template>
  <el-dialog class="dialog" width="30%" @close="cancel" destroy-on-close :visible.sync="dialogTableVisible">
    <p class="header-title">发起意向合作</p>
    <div class="service-info">
      <div style="display: flex; align-items: center">
        <el-avatar :size="30" shape="square" :src="require('@/assets/images/avatar.jpg')"></el-avatar>
        <span class="ml20">{{ productDetails.serviceName || productDetails.relevanceTitle }}</span>
      </div>
      <span
        class="ml20 price color-important bold"
        v-if="productDetails.serviceSpecsType === '0'"
        style="flex-shrink: 0"
        >￥<span class="font-size-max">{{ $math.divide(productDetails.price || 0).toFixed(2) }}</span></span
      >
    </div>
    <div class="leaveAMessage">
      <p class="minor">合作留言</p>
      <el-input
        type="textarea"
        placeholder="请输入留言"
        v-model="leaveAMessage"
        ref="textarea"
        autofocus
        class="mb20"
        show-word-limit
        :autosize="{ minRows: 5, maxRows: 5 }"
      >
      </el-input>
      <p class="minor" style="margin-top: 20px">添加附件</p>
      <FileUpload v-model="files"></FileUpload>
      <div class="text-center mt10">
        <el-button type="primary" @click="initiateCooperation" :loading="loading">发起合作 </el-button>
      </div>
    </div>
    <el-dialog
      class="orderDia"
      title="订单支付"
      :visible.sync="payVisible"
      :close-on-click-modal="false"
      width="1200px"
      append-to-body
      @close="colseDia"
      v-if="payVisible"
    >
      <!--      -->
      <div class="orderBox">
        <!-- <img src="~@/assets/images/pay-bg.png" alt="" /> -->
        <!-- <div>倒计时：{{ orderEndTime }}</div> -->
        <div class="left" style="position: relative">
          <p class="left-title">订单信息</p>
          <img
            src="@/assets/images/orderLogo.png"
            style="width: 180px; height: 160px; background: none; position: absolute; right: 0; bottom: 20px"
            alt=""
          />
          <el-descriptions style="width: 700px" class="margin-top" :column="1" border>
            <el-descriptions-item>
              <template slot="label"> 服务商： </template>
              <div class="font700">{{ orderDetails.echoMap.providerId.enterpriseName }}</div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 产品： </template>
              <div class="product">
                <div class="font700">{{ orderDetails.echoMap.serviceId.serviceName }}</div>
                <img
                  v-if="proImg"
                  style="width: 130px; height: 80px; background: none; margin-top: 10px"
                  :src="proImg"
                  alt=""
                />
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 价格： </template>
              <div class="font700 colFF8544">
                <span style="font-size: 14px; margin-right: 10px">￥</span>{{ orderDetails.orderBalance }}
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="text-center">
          <!-- <p class="mb10 text-center font-size-large" style="color: rgb(7, 193, 96)">微信扫码支付</p> -->
          <p style="color: #303133; text-align: center; margin-bottom: 26px">微信扫码支付</p>
          <div v-show="orderFlag === 0" ref="qrcode" class="qrcode"></div>
          <img v-show="orderFlag === 1" src="@/assets/images/cur.png" alt="" />
          <img v-show="orderFlag === 2" src="@/assets/images/ero.png" alt="" />
          <div v-if="orderFlag === 0" class="countdown">
            倒计时：<span>{{ orderEndTime[0] }}</span
            >分<span>{{ orderEndTime[1] }}</span
            >秒
          </div>
          <div style="margin-top: 10px" v-if="orderFlag === 1">您已完成支付</div>
          <div style="margin-top: 10px" v-if="orderFlag === 2">您的订单支付失败</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer orderBtn">
        <el-button type="primary" plain v-if="orderFlag === 0" @click="colseDia">取消</el-button>
        <el-button v-if="orderFlag === 1" class="curOrder" @click="colseDia(true)">支付完成</el-button>
        <el-button v-if="orderFlag === 2" type="primary" @click="colseDia" plain>订单未支付，关闭订单</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import FileUpload from '@/components/FileUpload'
import { cancelOrder, order, orderDetail, orderInfo, orderPay, placeAnOrder } from '@/api/order'
import QRCode from 'qrcodejs2'

export default {
  name: 'Cooperation' /*发起合作*/,
  components: { FileUpload },
  data() {
    return {
      leaveAMessage: '' /*合作留言*/,
      files: '',
      dialogTableVisible: false /*弹窗*/,
      productDetails: {} /*服务信息*/,
      orderDetails: {} /*订单详情*/,
      loading: false,
      payVisible: false /*支付模态框*/,
      timer: null /*定时器*/,
      orderTimer: null, //订单结束定时器
      orderEndTime: [], //订单结束时间
      endTime: null,
      orderFlag: 0, //订单支付状态
      proImg: '',
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.orderTimer)
  },
  methods: {
    //清空数据
    clearData() {
      this.leaveAMessage = ''
      this.files = ''
      this.productDetails = {}
      this.loading = false
    },
    colseDia(flag) {
      this.clearData()
      this.payVisible = false
      this.orderCancel()
      if (flag === true) {
        this.$router.push({
          path: '/more/personalCenter',
          query: { currentIndex: '2' },
        })
      }
    },
    //发起合作
    initiateCooperation() {
      this.orderFlag = 0

      this.loading = true
      this.proImg = sessionStorage.getItem('proImg')

      let parm = {
        serviceId: this.productDetails.relevanceId || this.productDetails.id,
        customerMessage: this.leaveAMessage,
        files:
          (this.files &&
            this.files.split(',').map((item) => {
              return { fileUrl: item }
            })) ||
          '',
      }
      if (!parm.files) delete parm.files
      order(parm)
        .then(async (res) => {
          // this.$message.success('发起成功！')
          this.dialogTableVisible = false
          if (this.productDetails.serviceSpecsType === '0') {
            this.orderDetails = res.data
            // let enDtime = this.$moment(res.data.orderDelayTime).format('X')
            // let startime = this.$moment().format('X')
            this.endTime = res.data.orderDelayTime
            this.orderTimer = setInterval(() => {
              // 获取当前时间
              var now = new Date()

              // 设置时间后两秒
              now.setSeconds(now.getSeconds() + 1)
              const diff = new Date(this.endTime) - now
              if (diff <= 0) {
                this.orderEndTime = []
                clearInterval(this.orderTimer)
                this.$message.error('订单超时放弃支付')
                this.orderFlag = 2
                this.orderCancel()
              } else {
                const minutes = Math.floor(diff / 1000 / 60)
                const seconds = Math.floor((diff / 1000) % 60)
                // this.orderEndTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
                this.orderEndTime = [String(minutes).padStart(2, '0'), String(seconds).padStart(2, '0')]
              }
            }, 1000)
            await this.pay()
          } else {
            await this.$router.push({
              path: '/more/personalCenter',
              query: { currentIndex: '2' },
            })
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    cancel() {
      this.clearData()
      this.dialogTableVisible = false
    },
    show(productDetails) {
      this.dialogTableVisible = true
      this.productDetails = productDetails
    },
    //支付弹窗关闭
    async orderCancel() {
      this.orderEndTime = '10:00'
      if (this.orderTimer) {
        clearInterval(this.orderTimer)
      }
      // this.payVisible = false
      if (this.timer) {
        clearInterval(this.timer)
      }

      // await cancelOrder({
      //   id: this.orderDetails.id,
      //   cancelMessage: '关闭支付',
      // })
      this.clearData()
    },
    //支付
    async pay() {
      this.payVisible = true
      let result = await orderPay({ id: this.orderDetails.id })
      let qrcode = new QRCode(this.$refs.qrcode, {
        text: result.data, // 需要转换为二维码的内容
        width: 230,
        height: 230,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })
      this.timer = setInterval(() => {
        // setTimeout(() => {
        this.queryOrderPayStatus()
        // }, 1000)
      }, 2000)
    },
    //获取订单详情
    async getOrderDetails() {
      let result = await orderDetail({ id: this.$route.query.id })
      this.orderDetails = result.data
      if (result.data.echoMap.score && result.data.echoMap.score.length > 0) {
        this.orderDetails.scoreData = result.data.echoMap.score[result.data.echoMap.score.length - 1]
      } else {
        this.orderDetails.scoreData = ''
      }
    },
    //查询订单支付状态
    async queryOrderPayStatus() {
      await orderInfo({ id: this.orderDetails.id })
        .then((result) => {
          if (result.data.payStatus === '1') {
            this.$message.success('支付成功！')
            this.orderCancel()
            this.orderFlag = 1
            // this.payVisible = false
          }
        })
        .catch(() => {
          this.orderCancel()
        })
    },
  },
}
</script>

<style scoped lang="stylus">
@import '@/assets/stylus/index.styl'
.qrcode{
  padding: 5px
  box-sizing: border-box
    border:  1px solid rgba(112, 112, 112, 0.2);
      border-radius: 5px
}
.orderBtn{
  >>>.el-button{
    width 188px
    height 40px
  }
  .curOrder{
    background: #07BA7E
    border: none
    color: #fff
  }
}

>>> .el-dialog__header:first-child{
  padding 0
  border none
}

.orderDia{
  .left{
    margin-right 50px
  }
  >>> .el-dialog__footer{

  box-shadow: 0px -4px 10px 0px rgba(26, 179, 153, 0.2);
  text-align: center !important
    }
    >>> .el-dialog__header{
      height: 60px
      box-sizing: border-box
      padding: 20px
      border-bottom: 1px solid #E4E7ED;
      font-size 14px
    }

    .left-title{
      font-size 20px
      font-weight: 700
      color: #999
      margin-bottom 20px
    }
    .orderBox{
      display: flex
      padding: 10px 80px
    }
    >>> .is-bordered-label {
      background: #f4f4f4 !important
      width 100px
      text-align: right !important
    }
    >>> .el-descriptions-item__cell{
      border: none !important
      white-space: nowrap
      font-size 18px !important
      background: rgba(26,146,243,0.03);
    }
    >>> .el-descriptions{
      border:  1px solid rgba(112, 112, 112, 0.2);
      border-radius: 5px
    }
    .product{
      max-height: 200px !important
    }
    .font700{
      font-weight: 700
    }
    .colFF8544{
      color: #FF8544
      font-size 30px
    }
    .countdown{
      width: 240px;
      height: 40px;
      text-align: center;
      background: linear-gradient(180deg, #FFD9B6 0%, #FFBF83 100%);
      display: flex
      align-items: center
      justify-content: center
      margin-top: 20px
      border-radius: 20px
      box-shadow: 0px 4px 10px 0px rgba(255, 157, 65, 0.3);
      span{
        display: inline-block
        padding: 3px
        border-radius: 3px
        background: #fff
        color: #FA5151
        margin: 0 5px
      }
    }
}

.dialog{
  .header-title{
    border-bottom:1px solid rgb(232, 232, 232);
    font-size:22px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    font-weight: bold;
  }
  .service-info{
    height:80px;
    border:1px solid rgb(222, 223, 226);
    background:linear-gradient(180deg, rgba(26, 146, 243, 0.08) 0%, rgba(26, 146, 243, 0.08) 0%, rgba(255, 255, 255, 0.00) 100%, rgba(255, 255, 255, 0.00) 100%);
    display: flex;
    align-items: center;
    padding:20px;
    margin-bottom: 20px;
  justify-content:space-between;
    .ml20{
      margin-left: 20px;
    }

  }

  .minor{
    color: #7B7D8C;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
  }

}
</style>
