const state = {
  token: '',
  userType: '' /*用户类型*/,
  name: '',
  avatar: '',
  userId: '',
  userInfo: {},
  currentHomeModal: '/home' /*当前首页模块名*/,
  navClassify: [] /*首页导航*/
}
export default state
