// 全局过滤器
import Vue from 'vue'

/**
 *    订单状态 3正在服务中  null咨询中
 */
Vue.filter('orderStatus', status => {
  let val = ''
  switch (status) {
    case 0:
      val = '待确认'
      break
    case 2:
      val = '待支付'
      break
    case 3:
      val = '服务中'
      break
    case '':
      val = '咨询中'
      break
    case 4:
      val = '已完成'
      break
  }
  return val
})

/**
 *    跟踪状态 3-待跟踪
 */
Vue.filter('demandStatus', status => {
  let val = ''
  switch (status) {
    case '0':
      val = '跟踪中'
      break
    case '1':
      val = '已完成'
      break
    case '2':
      val = '已取消'
      break
    case '3':
      val = '待跟踪'
      break
  }
  return val
})
/**
 *    下单状态  0待确认 1已取消 2待支付 3进行中 4已完成
 */
Vue.filter('placeOrderStatus', status => {
  let val = ''
  switch (status) {
    case '0':
      val = '待确认'
      break
    case '1':
      val = '已取消'
      break
    case '2':
      val = '待支付'
      break
    case '3':
      val = '进行中'
      break
    case '4':
      val = '已完成'
      break
  }
  return val
})
/**
 *    招聘状态 1招聘中  0已停止
 */
Vue.filter('recruitmentStatus', status => {
  let val = ''
  switch (status) {
    case '1':
      val = '招聘中'
      break
    case '0':
      val = '已停止'
      break
  }
  return val
})
/**
 *    审核状态  0-审核中 1-审核通过 2-审核不通过")
 */
Vue.filter('approvalStatusTwo', status => {
  let val = ''
  switch (status) {
    case '0':
      val = '审核中'
      break
    case '1':
      val = '已通过'
      break
    case '2':
      val = '未通过'
      break
    case '3':
      val = '已完成'
      break
  }
  return val
})
/**
 *    审核状态  0-审核中 1-审核通过 2-审核不通过")
 */
Vue.filter('approvalStatus', status => {
  let val = ''
  switch (status) {
    case '0':
      val = '审核中'
      break
    case '1':
      val = '审核通过'
      break
    case '2':
      val = '审核不通过'
      break
  }
  return val
})
/**
 *    星期
 */
Vue.filter('week', week => {
  let val = ''
  switch (week) {
    case '1':
      val = '周一'
      break
    case '2':
      val = '周二'
      break
    case '3':
      val = '周三'
      break
    case '4':
      val = '周四'
      break
    case '5':
      val = '周五'
      break
    case '6':
      val = '周六'
      break
    case '7':
      val = '周天'
      break
  }
  return val
})
