<template>
  <div class="construction">
    <div class="con_header">
      <div class="left">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          class="left_item"
          :class="{ active: currentIndex == index }"
          @click="handleClick(index, item)"
        >
          <div class="left_name">{{ item.categoryName }}</div>
          <el-divider direction="vertical" />
        </div>
      </div>
      <div class="more" @click="cliMore">更多产品>></div>
    </div>
    <div class="con_content">
      <div v-if="dataSource && currentIndex != 0" style="height: 100%">
        <div class="data_top">
          <div class="left" v-if="dataSource.length" @click="toInfo(dataSource[0])">
            <div class="cover">
              <img
                class="hvr-grow"
                :src="
                  dataSource[0].echoMap && dataSource[0].echoMap.images && dataSource[0].echoMap.images.length
                    ? dataSource[0].echoMap.images[0].imagesUrl
                    : require('@/assets/images/empty.png')
                "
              />
            </div>
            <div class="bottom">
              <div class="flex space-between">
                <div class="bottom_title no_two_wrap">{{ dataSource[0].serviceName }}</div>
                <div class="data_score">
                  {{ dataSource[0].serviceScore ? dataSource[0].serviceScore + '分' : '5.0分' }}
                </div>
              </div>
              <div class="bottom_desc no_one_wrap">{{ dataSource[0].serviceTitle }}</div>
              <div class="data_con_bottom" style="margin: 20px 0">
                <div class="data_tag">平台优选</div>
                <div class="data_price">
                  <span>价格：</span>
                  <span class="price"
                    >￥{{
                      dataSource[0].serviceSpecsType == '0' && dataSource[0].echoMap.specs[0].specsBalance
                        ? dataSource[0].echoMap.specs[0].specsBalance
                        : '面议'
                    }}</span
                  >
                </div>
              </div>
              <div class="data_btn">
                <el-button class="hvr-shadow" @click.stop="toChat(dataSource[0])">咨询</el-button>
                <el-button class="order hvr-shadow" @click.stop="initiateCooperation(dataSource[0])">下单</el-button>
              </div>
              <!--              <div class="bottom_btn" @click="toInfo(dataSource[0])">-->
              <!--                查看详情<i class="el-icon-right" style="margin-left: 20px"></i>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="right">
            <div v-for="(item, index) in dataSource.slice(1, 4)" :key="index" class="data_item" @click="toInfo(item)">
              <div class="cover">
                <img
                  class="hvr-grow"
                  :src="
                    item.echoMap && item.echoMap.images && item.echoMap.images.length
                      ? item.echoMap.images[0].imagesUrl
                      : require('@/assets/images/empty.png')
                  "
                />
              </div>
              <div class="data_content">
                <div class="data_con_title">
                  <div class="data_title no_one_wrap">{{ item.serviceName }}</div>
                  <div class="data_score">{{ item.serviceScore ? item.serviceScore + '分' : '5.0分' }}</div>
                </div>
                <div class="data_desc no_one_wrap">
                  {{ item.serviceTitle }}
                </div>
                <div class="data_con_bottom">
                  <div class="data_tag">平台优选</div>
                  <div class="data_price">
                    <span>价格：</span>
                    <span class="price"
                      >￥{{
                        item.serviceSpecsType == '0' && item.echoMap.specs[0].specsBalance
                          ? item.echoMap.specs[0].specsBalance
                          : '面议'
                      }}</span
                    >
                  </div>
                </div>
                <div class="data_btn">
                  <el-button class="hvr-shadow" @click.stop="toChat(item)">咨询</el-button>
                  <el-button class="order hvr-shadow" @click.stop="initiateCooperation(item)">下单</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data_bottom">
          <div v-for="(item, index) in dataSource.slice(4, 9)" :key="index" class="data_item" @click="toInfo(item)">
            <div class="cover">
              <img
                class="hvr-grow"
                :src="
                  item.echoMap && item.echoMap.images && item.echoMap.images.length
                    ? item.echoMap.images[0].imagesUrl
                    : require('@/assets/images/empty.png')
                "
              />
            </div>
            <div class="data_content">
              <div class="data_con_title">
                <div class="data_title no_one_wrap">{{ item.serviceName }}</div>
                <div class="data_score">{{ item.serviceScore ? item.serviceScore + '分' : '5.0分' }}</div>
              </div>
              <div class="data_desc no_one_wrap">
                {{ item.serviceTitle }}
              </div>
              <div class="data_con_bottom">
                <div class="data_tag">平台优选</div>
                <div class="data_price">
                  <span>价格：</span>
                  <span class="price"
                    >￥{{
                      item.serviceSpecsType == '0' && item.echoMap.specs[0].specsBalance
                        ? item.echoMap.specs[0].specsBalance
                        : '面议'
                    }}</span
                  >
                </div>
              </div>

              <div class="data_btn">
                <el-button class="hvr-shadow" @click.stop="toChat(item)">咨询</el-button>
                <el-button class="order hvr-shadow" @click.stop="initiateCooperation(item)">下单</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="dataList && currentIndex == 0" style="height: 100%">
        <div class="data_top">
          <div class="left" v-if="dataList.length" @click="toInfo(dataList[0])">
            <div class="cover">
              <img
                class="hvr-grow"
                :src="
                  dataList[0].echoMap && dataList[0].echoMap.images && dataList[0].echoMap.images.length
                    ? dataList[0].echoMap.images[0].imagesUrl
                    : require('@/assets/images/empty.png')
                "
              />
            </div>
            <div class="bottom">
              <div class="flex space-between">
                <div class="bottom_title no_two_wrap">{{ dataList[0].relevanceName }}</div>
                <div class="data_score">{{ dataList[0].serviceScore ? dataList[0].serviceScore + '分' : '5.0分' }}</div>
              </div>
              <div class="bottom_desc no_one_wrap">{{ dataList[0].relevanceTitle }}</div>
              <div class="data_con_bottom" style="margin: 20px 0">
                <div class="data_tag">平台优选</div>
                <div class="data_price">
                  <span>价格：</span>
                  <span class="price"
                    >￥{{
                      dataList[0].serviceSpecsType == '0' && dataList[0].echoMap.specs[0].specsBalance
                        ? dataList[0].echoMap.specs[0].specsBalance
                        : '面议'
                    }}</span
                  >
                </div>
              </div>
              <div class="data_btn">
                <el-button class="hvr-shadow" @click.stop="toChat(dataList[0])">咨询</el-button>
                <el-button class="order hvr-shadow" @click.stop="initiateCooperation(dataList[0])">下单</el-button>
              </div>
              <!--              <div class="bottom_title no_two_wrap">{{ dataList[0].relevanceName }}</div>-->
              <!--              <div class="bottom_desc no_one_wrap">{{ dataList[0].relevanceTitle }}</div>-->
              <!--              <div class="bottom_btn" @click="toInfo(dataList[0])">-->
              <!--                查看详情<i class="el-icon-right" style="margin-left: 20px"></i>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="right">
            <div v-for="(item, index) in dataList.slice(1, 4)" :key="index" class="data_item" @click="toInfo(item)">
              <div class="cover">
                <img
                  class="hvr-grow"
                  :src="
                    item.echoMap && item.echoMap.images && item.echoMap.images.length
                      ? item.echoMap.images[0].imagesUrl
                      : require('@/assets/images/empty.png')
                  "
                />
              </div>
              <div class="data_content">
                <div class="data_con_title">
                  <div class="data_title no_one_wrap">{{ item.relevanceName }}</div>
                  <div class="data_score">{{ item.serviceScore ? item.serviceScore + '分' : '5.0分' }}</div>
                </div>
                <div class="data_desc no_one_wrap">
                  {{ item.relevanceTitle }}
                </div>
                <div class="data_con_bottom">
                  <div class="data_tag">平台优选</div>
                  <div class="data_price">
                    <span>价格：</span>
                    <span class="price"
                      >￥{{
                        item.serviceSpecsType == '0' && item.echoMap.specs[0].specsBalance
                          ? item.echoMap.specs[0].specsBalance
                          : '面议'
                      }}</span
                    >
                  </div>
                </div>

                <div class="data_btn">
                  <el-button class="hvr-shadow" @click.stop="toChat(item)">咨询</el-button>
                  <el-button class="order hvr-shadow" @click.stop="initiateCooperation(item)">下单</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data_bottom">
          <div v-for="(item, index) in dataList.slice(4, 9)" :key="index" class="data_item" @click="toInfo(item)">
            <div class="cover">
              <img
                class="hvr-grow"
                :src="
                  item.echoMap && item.echoMap.images && item.echoMap.images.length
                    ? item.echoMap.images[0].imagesUrl
                    : require('@/assets/images/empty.png')
                "
              />
            </div>
            <div class="data_content">
              <div class="data_con_title">
                <div class="data_title no_one_wrap">{{ item.relevanceName }}</div>
                <div class="data_score">{{ item.serviceScore ? item.serviceScore + '分' : '5.0分' }}</div>
              </div>
              <div class="data_desc no_one_wrap">
                {{ item.relevanceTitle }}
              </div>
              <div class="data_con_bottom">
                <div class="data_tag">平台优选</div>
                <div class="data_price">
                  <span>价格：</span>
                  <span class="price"
                    >￥{{
                      item.serviceSpecsType == '0' && item.echoMap.specs[0].specsBalance
                        ? item.echoMap.specs[0].specsBalance
                        : '面议'
                    }}</span
                  >
                </div>
              </div>
              <div class="data_btn">
                <el-button class="hvr-shadow" @click.stop="toChat(item)">咨询</el-button>
                <el-button class="order hvr-shadow" @click.stop="initiateCooperation(item)">下单</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Cooperation ref="cooperation"></Cooperation>
  </div>
</template>

<script>
import { serviceFirstClassify, serviceList, serviceSecondClassify } from '@/api/common'
import tableList from '@/mixins/tableList'
import { mapGetters } from 'vuex'
import Cooperation from '@/components/Cooperation/Cooperation.vue'

export default {
  components: { Cooperation },
  props: {
    navId: {
      type: Number,
      default: 168,
    },
  },
  mixins: [tableList],
  data() {
    return {
      tabList: [],
      dataList: [],
      disabledLoadData: true,
      url: {
        list: '/api/kjcy/bService/bServiceListByPage',
      },
      ipagination: {
        current: 1,
        size: 21,
      } /* 分页参数 */,
      currentIndex: 0,
    }
  },
  created() {},
  computed: {
    ...mapGetters(['userType', 'userInfo']),
  },
  mounted() {
    this.getTabList()
  },
  methods: {
    async getTabList() {
      // console.log(this.navId)
      let { data } = await serviceFirstClassify({ parentId: this.navId, systemType: '1' })
      this.tabList = data.length ? (data.length > 7 ? data.slice(0, 6) : data) : []
      this.tabList = [{ categoryName: '精选', id: '精选' }, ...this.tabList]
      await this.getConcentration()
    },
    async getConcentration() {
      let res = await serviceList({ serviceCategoryId: this.navId, type: '2' })
      this.dataList = res.data.length ? (res.data.length > 10 ? res.data.slice(0, 10) : res.data) : []
    },
    cliMore() {
      this.$router.push({
        path: '/more/product',
        query: {
          id: this.navId,
        },
      })
    },
    getList(id) {
      this.queryParams = {
        approvalStatus: '1',
        providerState: '1',
        serviceSecondType: id,
        serviceStatus: '1',
      }
      this.loadData()
    },
    handleClick(index, item) {
      // console.log(tab,event)
      this.currentIndex = index
      if (index != 0) {
        this.getList(Number(item.id))
      }
    },
    toInfo(item) {
      // console.log(item, 'item')
      // this.$bus.$emit('getWXUrl', item.wxCodeUrl)
      this.$router.push({
        path: '/more/product/info',
        query: {
          id: item.relevanceId || item.id,
        },
      })
    },
    toChat(item) {
      this.$router.push({
        path: '/more/chat',
        query: { serviceId: item.relevanceId || item.id, providerId: item.providerId },
      })
    },
    //发起合作
    async initiateCooperation(item) {
      sessionStorage.setItem('proImg', (item.echoMap.images && item.echoMap.images[0].imagesUrl) || '')
      if (this.userType === 'user') {
        this.$refs.cooperation.show({
          ...item,
          price: item.echoMap?.specs[0].specsBalance,
        })
      } else {
        this.$router.push({
          path: `/login?redirect=${this.$route.fullPath}`,
          replace: true,
        })
      }
    },
  },
  watch: {
    navId(val) {
      this.getTabList()
      this.currentIndex = 0
    },
  },
}
</script>

<style lang="less" scoped>
.construction {
  margin-top: -10px;
  //padding:0 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  .con_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 38px;
    margin-bottom: 10px;

    .left {
      display: flex;

      .left_item {
        font-family: Source Han Sans CN;
        font-size: 18px;
        font-weight: normal;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0px;
        color: #232536;
        cursor: pointer;
        display: flex;
        align-items: center;

        &.active {
          font-weight: bold;
          color: @btn-font-color;

          .left_name {
            border-bottom: 2px solid @btn-font-color;
          }
        }

        ::v-deep .el-divider {
          margin: 0 30px;
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .more {
      color: #232536;
      cursor: pointer;
    }
  }

  .con_content {
    height: calc(100% - 48px);

    .data_top {
      display: flex;
      margin-bottom: 14px;
      height: 50%;

      .left {
        position: relative;
        width: 38.7%;
        margin-right: 2%;
        flex-shrink: 0;
        cursor: pointer;
        height: 100%;
        overflow: hidden;
        border-radius: 4px;

        .cover {
          width: 100%;
          height: 100%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .bottom {
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
          //opacity: 0.6;
          z-index: 999;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%);
          padding: 40px 29px;
          box-sizing: border-box;
          transition: all 0.5s;
          transform: translateX(-100%);
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .bottom_title {
            font-family: Alimama ShuHeiTi;
            font-size: 30px;
            font-weight: bold;
            line-height: 36px;
            letter-spacing: 0px;
            color: #ffffff;
            text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
            margin-bottom: 22px;
          }

          .bottom_desc {
            font-family: DMSans-Regular;
            font-size: 14px;
            font-weight: 350;
            //line-height: 36px;
            letter-spacing: 0px;
            opacity: 0.8;
            //min-height: 72px;
            //margin-bottom: 35px;
            color: #ffffff;
          }

          .bottom_btn {
            font-family: Poppins-Medium;
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0px;
            color: @btn-font-color;
          }
        }

        &:hover {
          .bottom {
            transform: translateX(0);
          }
        }
      }

      .right {
        display: flex;
        flex-wrap: wrap;
        width: 60%;

        .data_item {
          width: 31%;
          margin-right: 3.5%;
          //margin-bottom: 20px;
          cursor: pointer;
          height: 100%;
          border-radius: 4px;
          overflow: hidden;

          &:hover {
            .data_title {
              color: @btn-font-color;
            }
          }

          .cover {
            width: 100%;
            height: 45%;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .data_content {
            padding: 17px 20px;
            box-sizing: border-box;
            background: #ffffff;
            height: calc(100% - 45%);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          .data_con_title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .data_score {
              color: #ff8f1f;
              font-family: Source Han Sans CN;
              font-size: 14px;
              font-weight: normal;
              letter-spacing: 0px;
              min-width: 33.8px;
              text-align: right;
            }
          }

          .data_title {
            font-family: Source Han Sans CN;
            font-size: 18px;
            font-weight: bold;
            line-height: 25px;
            letter-spacing: 0px;
            color: #222222;
            text-align: left;
            //margin-bottom: 17px;
          }

          .data_desc {
            font-family: Source Han Sans CN;
            font-size: 14px;
            font-weight: normal;
            line-height: 21px;
            letter-spacing: 0px;
            color: #7b7d8c;
            min-height: 25px;
            //margin-bottom: 17px;
          }

          .data_con_bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            //margin-bottom: 18px;

            .data_tag {
              color: #979797;
              font-family: Raleway-Regular;
              font-size: 12px;
              font-weight: 350;
              line-height: 17px;
              letter-spacing: 0px;
              border-radius: 8px;
              background: rgba(121, 121, 121, 0.1);
              width: 68px;
              height: 22px;
              text-align: center;
              line-height: 22px;
            }

            .data_price {
              color: #979797;
              font-size: 12px;
              font-family: Raleway-Regular;
              font-weight: 350;
              letter-spacing: 0px;

              .price {
                font-family: Source Han Sans CN;
                font-size: 18px;
                font-weight: bold;
                line-height: 26px;
                text-align: right;
                letter-spacing: 0px;
                color: #0d5697;
              }
            }
          }

          .data_btn {
            display: flex;
            width: 100%;

            ::v-deep .el-button {
              width: 50%;
              border-radius: 4px;
              border: 1px solid @btn-font-color;
              color: @btn-font-color;
              height: 29px;
              //line-height: 29px;
              padding: 4px 20px;
              font-size: 14px;
            }

            .order {
              margin-left: 8px;
              border: 1px solid @btn-font-color;
              background: @btn-font-color;
              color: #ffffff;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .data_bottom {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 50%;

      .data_item {
        width: 18.4%;
        margin-right: 2%;
        //margin-bottom: 20px;
        cursor: pointer;
        height: 100%;
        border-radius: 4px;
        overflow: hidden;

        &:hover {
          .data_title {
            color: @btn-font-color;
          }
        }

        .cover {
          width: 100%;
          height: 45%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .data_content {
          padding: 17px 20px;
          box-sizing: border-box;
          background: #ffffff;
          height: calc(100% - 45%);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .data_con_title {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .data_score {
            color: #ff8f1f;
            font-family: Source Han Sans CN;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0px;
            min-width: 33.8px;
            text-align: right;
          }
        }

        .data_title {
          font-family: Source Han Sans CN;
          font-size: 18px;
          font-weight: bold;
          line-height: 25px;
          letter-spacing: 0px;
          color: #222222;
          text-align: left;
          //margin-bottom: 17px;
        }

        .data_desc {
          font-family: Source Han Sans CN;
          font-size: 14px;
          font-weight: normal;
          line-height: 21px;
          letter-spacing: 0px;
          color: #7b7d8c;
          min-height: 25px;
          //margin-bottom: 17px;
        }

        .data_con_bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          //margin-bottom: 18px;

          .data_tag {
            color: #979797;
            font-family: Raleway-Regular;
            font-size: 12px;
            font-weight: 350;
            line-height: 17px;
            letter-spacing: 0px;
            border-radius: 8px;
            background: rgba(121, 121, 121, 0.1);
            width: 68px;
            height: 22px;
            text-align: center;
            line-height: 22px;
          }

          .data_price {
            color: #979797;
            font-size: 12px;
            font-family: Raleway-Regular;
            font-weight: 350;
            letter-spacing: 0px;

            .price {
              font-family: Source Han Sans CN;
              font-size: 18px;
              font-weight: bold;
              line-height: 26px;
              text-align: right;
              letter-spacing: 0px;
              color: #0d5697;
            }
          }
        }

        .data_btn {
          display: flex;
          width: 100%;

          ::v-deep .el-button {
            width: 50%;
            border-radius: 4px;
            border: 1px solid @btn-font-color;
            color: @btn-font-color;
            height: 29px;
            //line-height: 29px;
            padding: 4px 20px;
            font-size: 14px;
          }

          .order {
            margin-left: 8px;
            border: 1px solid @btn-font-color;
            background: @btn-font-color;
            color: #ffffff;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  //::v-deep .el-tabs__nav-scroll {
  //  height: 48px;
  //  //background: rgba(255, 255, 255, 0.15);
  //  //border: 2px solid rgba(255, 255, 255, 0.15);
  //}
  //
  //::v-deep .el-tabs__nav-wrap::after {
  //  background-color: transparent !important;
  //}
  //
  //::v-deep .el-tabs__nav :last-child {
  //  border: 0px !important;
  //}
  //
  //.const_tabs {
  //  ::v-deep .el-tabs__item {
  //    color: #fff;
  //    font-size: 18px;
  //    height: 35px;
  //    width: 210px;
  //    box-sizing: border-box;
  //    text-align: center;
  //    line-height: 35px;
  //    margin-top: 8px;
  //    padding: 0;
  //    border-right: 2px solid rgba(255, 255, 255, 0.15);
  //
  //    &:hover {
  //      color: #fff;
  //    }
  //  }
  //
  //  ::v-deep .el-tabs__active-bar {
  //    background: #fff;
  //    bottom: -18px;
  //    width: 100px !important;
  //    // height: 0 !important;
  //    margin-left: 60px;
  //  }
  //}
  .data_score {
    color: #ff8f1f;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    min-width: 33.8px;
    text-align: right;
  }

  .data_con_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-bottom: 18px;

    .data_tag {
      color: #ffffff;
      font-family: Raleway-Regular;
      font-size: 12px;
      font-weight: 350;
      line-height: 17px;
      letter-spacing: 0px;
      border-radius: 8px;
      background: @btn-font-color;
      width: 68px;
      height: 22px;
      text-align: center;
      line-height: 22px;
    }

    .data_price {
      color: #ffffff;
      font-size: 12px;
      font-family: Raleway-Regular;
      font-weight: 350;
      letter-spacing: 0px;

      .price {
        font-family: Source Han Sans CN;
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
        text-align: right;
        letter-spacing: 0px;
        color: #0d5697;
      }
    }
  }

  .data_btn {
    display: flex;
    width: 100%;

    ::v-deep .el-button {
      width: 50%;
      border-radius: 4px;
      border: 1px solid @btn-font-color;
      color: @btn-font-color;
      height: 29px;
      //line-height: 29px;
      padding: 4px 20px;
      font-size: 14px;
    }

    .order {
      margin-left: 8px;
      border: 1px solid @btn-font-color;
      background: @btn-font-color;
      color: #ffffff;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

//
//::v-deep .el-tabs__header {
//  margin: 0;
//}
</style>
