import { axios } from '@/utils/request'

/**
 * 下单
 */
export function placeAnOrder(data) {
  return axios({
    url: `/api/kjcy/bOrder/create`,
    method: 'post',
    data,
  })
}

export function order(data) {
  return axios({
    url: `/api/kjcy/bOrder/createOrder`,
    method: 'post',
    data,
  })
}
/**
 * 订单详情
 */
export function orderDetail(data) {
  return axios({
    url: `/api/kjcy/bOrder/info`,
    method: 'post',
    data,
  })
}

/**
 * 订单支付
 */
export function orderPay(data) {
  return axios({
    url: `/api/kjcy/bOrder/wx/pay`,
    method: 'post',
    data,
  })
}

/**
 * 取消订单
 */
export function cancelOrder(data) {
  return axios({
    url: `/api/kjcy/bOrder/cancel`,
    method: 'post',
    data,
  })
}

/**
 * 订单信息
 */
export function orderInfo(data) {
  return axios({
    url: `/api/kjcy/bOrder/info`,
    method: 'post',
    data,
  })
}

/**
 * 订单评价
 */
export function orderScore(data) {
  return axios({
    url: `/api/kjcy/bOrderScore/score`,
    method: 'post',
    data,
  })
}
/**
 * 获取订单支付信息
 */
export function getUnPayOrder(data) {
  return axios({
    url: `/api/kjcy/bOrder/getUnPayOrder/${data}`,
    method: 'get',
  })
}
