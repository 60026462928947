import { postAction } from '@/api/manage'
import { filterObj } from '@/utils/utils'

export default {
  data() {
    return {
      queryParams: {} /* 查询条件 */,
      dataSource: [] /* 数据源 */,
      disabledLoadData: false /*禁用默认加载*/,
      ipagination: {
        /* 分页参数 */ current: 1,
        size: 10,
        total: 0,
      } /* 排序参数 */,
      arr: {},
    }
  },
  created() {
    if (!this.disabledLoadData) {
      this.loadData()
    }
  },
  methods: {
    //加载数据
    loadData() {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      let params = this.getQueryParams()
      postAction(this.url.list, params).then((result) => {
        this.dataSource = result.data.records || []

        this.arr = this.dataSource.length ? this.dataSource[0] : {}
        this.ipagination.total = result.data.total * 1
      })
    },
    //获取查询条件
    getQueryParams() {
      return filterObj({
        ...{
          model: {
            ...this.queryParams,
            ...this.otherQueryParams,
          },
        },
        ...this.ipagination,
      })
    },
  },
}
