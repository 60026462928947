import { axios } from '@/utils/request'

export function getAction(url, query) {
  return axios({
    url: url,
    method: 'get',
    params: query
  })
}

export function postAction(url, data) {
  return axios({
    url: url,
    method: 'post',
    data
  })
}

export function downFile(url,data){
  return axios({
    url: url,
    data,
    method:'post' ,
    responseType: 'arraybuffer',  //主要设置 responseType 为blob
    dataType: 'json',
    headers: {
      Accept: 'application/octet-stream',
      'Content-Type': 'application/json;'
    },
  })
}