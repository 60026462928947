import * as types from './mutation-types'

const mutations = {
  [types.SET_TOKEN]: (state, token) => {
    state.token = token
  },
  [types.SET_USER_TYPE]: (state, userType) => {
    state.userType = userType
  },
  [types.SET_NAME]: (state, name) => {
    state.name = name
  },
  [types.SET_AVATAR]: (state, avatar) => {
    state.avatar = avatar
  },
  [types.SET_USERID]: (state, userId) => {
    state.userId = userId
  },
  [types.SET_USERINFO]: (state, userInfo) => {
    state.userInfo = userInfo
  },
  [types.SET_CURRENT_HOME_MODAL]: (state, modal) => {
    state.currentHomeModal = modal
  },
  [types.SET_NAV_CLASSIFY]: (state, nav) => {
    state.navClassify = nav
  }
}
export default mutations
