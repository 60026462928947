import { axios } from '@/utils/request'

//游客登录
export function visitorLogin() {
  return axios({
    url: '/api/oauth/anonymous/login',
    method: 'post',
    data: { grantType: 'captcha' }
  })
}

//游客token解析
export function visitorInfo(query) {
  return axios({
    url: '/api/oauth/anno/verify',
    method: 'get',
    params: query
  })
}

//用户登录
export function userLogin(data) {
  return axios({
    url: '/api/oauth/noToken/login',
    method: 'post',
    data
  })
}

//注册
export function register(data) {
  return axios({
    url: '/api/oauth/register',
    method: 'post',
    data
  })
}

//注册获取验证码
export function getVerificationCode({ phone }) {
  return axios({
    url: `/api/oauth/user/sms/code/${phone}`,
    method: 'get'
  })
}

//忘记密码获取验证码
export function getForgetPasswordVerificationCode({ phone }) {
  return axios({
    url: `/api/oauth/user/sms/retrieve/password/code/${phone}`,
    method: 'get'
  })
}

//验证账号是否注册
export function checkPhone(data) {
  return axios({
    url: `/api/oauth/checkPhone`,
    method: 'get',
    params: data
  })
}

//账号密码登录
export function accountPasswordLogin(data) {
  return axios({
    url: `/api/oauth/noToken/login`,
    method: 'post',
    data
  })
}

//获取用户信息
export function getInfo({ id }) {
  return axios({
    url: `/api/authority/user/${id}`,
    method: 'get'
  })
}

//登出
export function logout(userId) {
  return axios({
    url: `/api/oauth/noToken/logout`,
    method: 'post',
    data: { userId }
  })
}

//注册验证验证码
export function verifyVerificationCode(data) {
  return axios({
    url: `/api/oauth/user/sms/code/v`,
    method: 'post',
    data
  })
}

//忘记密码验证验证码
export function forgotPasswordVerificationVerificationCode(data) {
  return axios({
    url: `/api/oauth/user/sms/retrieve/password/code/v`,
    method: 'post',
    data
  })
}

//忘记密码
export function forgotPassword(data) {
  return axios({
    url: `/api/oauth/retrieve/password`,
    method: 'put',
    data
  })
}

//重置密码
export function resetPassword(data) {
  return axios({
    url: `/api/authority/user/reset`,
    method: 'post',
    data
  })
}
