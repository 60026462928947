import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=6d66cbd6&scoped=true&"
import script from "./Pagination.vue?vue&type=script&lang=js&"
export * from "./Pagination.vue?vue&type=script&lang=js&"
import style0 from "./Pagination.vue?vue&type=style&index=0&id=6d66cbd6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d66cbd6",
  null
  
)

export default component.exports