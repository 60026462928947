import Cookies from 'js-cookie'

const UserTokenKey = 'User-Token' /*用户token*/
const visitorTokenKey = 'Visitor-Token' /*游客token*/

export function getUserTokenKey() {
  return Cookies.get(UserTokenKey)
}

export function getVisitorTokenKey() {
  return Cookies.get(visitorTokenKey)
}

export function setUserToken(token, expires) {
  return Cookies.set(UserTokenKey, token, { expires })
}

export function setVisitorToken(token, expires) {
  return Cookies.set(visitorTokenKey, token, { expires })
}

export function removeUserToken() {
  return Cookies.remove(UserTokenKey)
}

export function removeVisitorToken() {
  return Cookies.remove(visitorTokenKey)
}
