<template>
  <div class="main_content">
    <!-- 基础样式模块 -->
    <div class="mdoe1" :class="{ noFirst: currentIndex != 0 }">
      <!-- 顶部 -->
      <div class="con_top">
        <div class="logo" @click="$router.push('/')"></div>
        <div class="right">
          <div class="right_top">
            <div>
              <div
                class="right_login"
                v-if="!userInfo || JSON.stringify(userInfo) == '{}'"
                @click="$router.push('/login')"
              >
                登录/注册
              </div>
              <div class="flex person" v-if="userInfo && JSON.stringify(userInfo) != '{}'">
                <div class="drop">
                  <img class="cover" :src="userInfo.avatar || require('@/assets/images/avatar.jpg')" />
                  <el-dropdown>
                    <span class="name"> {{ userInfo.name }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="toPersonal({})">个人中心</el-dropdown-item>
                      <el-dropdown-item @click.native="signOut">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div>/</div>
                <div class="drop">
                  <el-badge
                    @click.native="toPersonal({ currentIndex: '2' })"
                    :value="userType === 'user' ? statistics.pendingOrderNum : ''"
                    class="item"
                  >
                    <i class="el-icon-s-claim"></i>待确认
                  </el-badge>
                </div>
                <div>/</div>
                <div class="drop">
                  <el-badge
                    @click.native="toPersonal({ currentIndex: '2' })"
                    :value="userType === 'user' ? statistics.pendingPaymentOrderNum : ''"
                    class="item"
                  >
                    <i class="el-icon-s-order"></i>

                    待付款
                  </el-badge>
                </div>
                <div>/</div>
                <div class="drop">
                  <el-badge
                    @click.native="toPersonal({ currentIndex: '2' })"
                    :value="userType === 'user' ? statistics.orderInServiceNum : ''"
                    class="item"
                  >
                    <i class="el-icon-tickets"></i>服务中
                  </el-badge>
                </div>
                <div>/</div>
                <div class="drop">
                  <el-badge
                    @click.native="toPersonal({ currentIndex: '0' })"
                    :value="userType === 'user' ? statistics.unreadConsultNum : ''"
                    class="item"
                  >
                    <i class="el-icon-chat-dot-round"></i>消息
                  </el-badge>
                </div>
                <div>/</div>
                <div class="drop" @click="toPersonal({ currentIndex: '2' })"><i class="el-icon-document"></i>订单</div>
              </div>
            </div>
            <div class="telephone">028-83585178</div>
          </div>
          <div class="right_bottom">
            <div class="search">
              <el-input class="searchIpt" placeholder="搜索" v-model="keyword"></el-input>
              <el-button class="ser_btn" @click="toProduct"><span>搜索</span></el-button>
            </div>
            <el-button class="server" type="primary" icon="el-icon-phone-outline" round>服务热线</el-button>
          </div>
        </div>
      </div>
      <!-- 主要显示区域 -->
      <div class="home_main" :class="{ isHome: $route.path == '/home' }">
        <div class="left">
          <div
            class="left_item"
            v-for="(item, index) in navClassify.slice(0, 6)"
            :key="index"
            :class="{ active: currentIndex === index }"
            @click="navChange(item, index)"
          >
            {{ item.categoryName }}
          </div>
        </div>
        <div class="center">
          <div v-if="currentIndex == 0" class="first">
            <el-carousel class="carousel" trigger="click" :height="height" :autoplay="false">
              <el-carousel-item v-for="(item, index) in homepageSelection" style="border: none" :key="index + 100">
                <div class="con_content" style="height: 100%">
                  <div class="data_top">
                    <div class="left" v-if="item.length" @click="toInfo(item[0])">
                      <div class="cover">
                        <img
                          class="hvr-grow"
                          :src="
                            item[0] && item[0].images && item[0].images.length
                              ? item[0].images[0].imagesUrl
                              : require('@/assets/images/empty.png')
                          "
                        />
                      </div>
                      <div class="bottom">
                        1
                        <div class="flex space-between">
                          <div class="bottom_title no_two_wrap">{{ item[0].relevanceName }}</div>
                          <div class="data_score">
                            {{ item[0].serviceScore ? item[0].serviceScore + '分' : '5.0分' }}
                          </div>
                        </div>
                        <div class="bottom_desc no_one_wrap">{{ item[0].relevanceTitle }}</div>
                        <div class="data_con_bottom" style="margin: 20px 0">
                          <div class="data_tag">平台优选</div>
                          <div class="data_price">
                            <span>价格：</span>
                            <span class="price"
                              >￥{{
                                item[0].serviceSpecsType == '0' && item[0].specs[0].specsBalance
                                  ? item[0].specs[0].specsBalance
                                  : '面议'
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="data_btn">
                          <el-button class="hvr-shadow" @click.stop="toChat(item[0])">咨询</el-button>
                          <el-button class="order hvr-shadow" @click.stop="initiateCooperation(item[0])"
                            >下单</el-button
                          >
                        </div>
                      </div>
                    </div>
                    <div class="right">
                      <div
                        v-for="(item, index) in item.slice(1, 4)"
                        :key="index"
                        class="data_item"
                        @click="toInfo(item)"
                      >
                        <div class="cover">
                          <img
                            class="hvr-grow"
                            :src="
                              item && item.images && item.images.length
                                ? item.images[0].imagesUrl
                                : require('@/assets/images/empty.png')
                            "
                          />
                        </div>
                        <div class="data_content">
                          <div class="data_con_title">
                            <div class="data_title no_one_wrap">{{ item.serviceName }}</div>
                            <div class="data_score">{{ item.serviceScore ? item.serviceScore + '分' : '5.0分' }}</div>
                          </div>
                          <div class="data_desc no_one_wrap">
                            {{ item.serviceTitle }}
                          </div>
                          <div class="data_con_bottom">
                            <div class="data_tag">平台优选</div>
                            <div class="data_price">
                              <span>价格：</span>
                              <span class="price"
                                >￥{{
                                  item.serviceSpecsType == '0' && item.servicePrice ? item.servicePrice : '面议'
                                }}</span
                              >
                            </div>
                          </div>

                          <div class="data_btn">
                            <el-button class="hvr-shadow" @click.stop="toChat(item)">咨询</el-button>
                            <el-button class="order hvr-shadow" @click.stop="initiateCooperation(item)">下单</el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="data_bottom">
                    <div v-for="(item, index) in item.slice(4, 9)" :key="index" class="data_item" @click="toInfo(item)">
                      <div class="cover">
                        <img
                          class="hvr-grow"
                          :src="
                            item && item.images && item.images.length
                              ? item.images[0].imagesUrl
                              : require('@/assets/images/empty.png')
                          "
                        />
                      </div>
                      <div class="data_content">
                        <div class="data_con_title">
                          <div class="data_title no_one_wrap">{{ item.serviceName }}</div>
                          <div class="data_score">{{ item.serviceScore ? item.serviceScore + '分' : '5.0分' }}</div>
                        </div>
                        <div class="data_desc no_one_wrap">
                          {{ item.serviceTitle }}
                        </div>
                        <div class="data_con_bottom">
                          <div class="data_tag">平台优选</div>
                          <div class="data_price">
                            <span>价格：</span>
                            <span class="price"
                              >￥{{
                                item.serviceSpecsType == '0' && item.specsBalance ? item.specsBalance : '面议'
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="data_btn">
                          <el-button class="hvr-shadow" @click.stop="toChat(item)">咨询</el-button>
                          <el-button class="order hvr-shadow" @click.stop="initiateCooperation(item)">下单</el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
              <template v-if="bannerFlag">
                <el-carousel-item v-for="(item, index) in dataSource" :key="index">
                  <Video
                    v-if="item.uploadType == 2"
                    :height="height"
                    @isPaused="isPaused"
                    :imageUrl="item.imageUrl"
                    :id="'dplayer' + index"
                  />

                  <img v-else style="width: 100%; height: 100%; background: none" :src="item.imageUrl" alt="" />
                </el-carousel-item>
              </template>
            </el-carousel>
            <!--            <div class="bottom"></div>-->
          </div>
          <dynamic v-else-if="currentIndex == 11" />

          <Construction :navId="navId" v-else />
        </div>
        <div class="right" ref="right">
          <div
            class="left_item"
            v-for="(item, index) in navClassify.slice(6, 12)"
            :key="index"
            :class="{ active: currentIndex === index + 6 }"
            @click="navChange(item, index + 6)"
          >
            {{ item.categoryName }}
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <div class="con_bottom">
        <div class="left">
          <div class="ewm" style="width: 120px; height: 120px">
            <img style="width: 100%; height: 100%; border-radius: 50%" :src="WxUrl" alt="" />
          </div>
          <div class="text">
            <p>扫一扫</p>
            <p>进入小程序</p>
          </div>
        </div>
        <div class="right">
          <div class="ewm">
            <img style="width: 100%; height: 100%" src="../assets/images/qiye.png" alt="" />
          </div>
          <div class="text">
            <p>扫一扫</p>
            <p>微信咨询</p>
          </div>
        </div>
        <div class="bottom_center">
          <div class="bot_top">
            <div
              class="hvr-float-shadow"
              v-for="(item, index) in bottomList"
              :key="index"
              :class="{ no_boder: bottomList.length == index + 1 }"
              @click="toGo(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="bot_bot">
            成都久广科技有限公司 版权所有&nbsp;
            <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备15002876号</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 平台动态模块 -->
    <!-- <div v-if="modeNum == 2" class="mode2">
      111
      <router-view></router-view>
    </div> -->
    <el-dialog title="客服热线" :visible.sync="dialogVisible" width="20%" class="kfDialog">
      <div>
        <div>
          <p>客服二维码</p>
          <div>
            <el-avatar shape="square" :size="100" :src="chatInfo.qrcode" fill="fill"></el-avatar>
          </div>
        </div>
        <el-divider />
        <div>
          <p class="el-icon-chat-dot-round wxChat" @click="toWechat" style="margin-bottom: 0">
            <span>在线客服</span>
          </p>
        </div>
        <el-divider />
        <div>
          <p>客服电话</p>
          <div class="el-icon-phone">
            {{ chatInfo.phone }}
          </div>
        </div>
        <el-divider />
        <div>
          <p>服务时间</p>
          <div>
            <div>{{ chatInfo.startDay | week }} 至 {{ chatInfo.endDay | week }}</div>
            <div>{{ chatInfo.time }}</div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <!--        <el-button @click="dialogVisible = false">取 消</el-button>-->
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <Cooperation ref="cooperation"></Cooperation>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from '@/store'
import { customerInfo, statisticsInfo, serviceSetListByPage } from '@/api/common'
import { navClassify, userInfo } from '@/store/getters'
import Construction from '@/views/digitalConstruction/Construction.vue'
import Video from '@/views/homeIndex/components/video/video.vue'
import tableList from '@/mixins/tableList'
import Dynamic from '@/views/dynamic/Dynamic.vue'
import Cooperation from '@/components/Cooperation/Cooperation.vue'

export default {
  mixins: [tableList],
  components: { Dynamic, Construction, Video, Cooperation },
  computed: {
    ...mapGetters(['currentHomeModal', 'userType', 'name', 'avatar', 'navClassify', 'userId', 'userInfo']),
  },
  data() {
    return {
      dialogVisible: false,
      chatInfo: {},
      show: false,
      keyword: '', //搜索值
      bottomList: [
        { name: '服务商入驻', path: '/more/serviceProviderCertification' },
        { name: '服务提供商', path: '/more/serviceProvider' },
        { name: '关于我们', path: '/more/aboutUs' },
        { name: '平台规则', path: '/more/platformRules' },
        { name: '法律声明', path: '/more/legalNotices' },
        { name: '线下网点', path: '/more/offlineServiceOutlets/list' },
        { name: '直播会议系统', path: '/more/liveBroadcast' },
        { name: 'erp系统', path: 'https://smdh3.35dinghuo.cn/' },
        { name: '大数据', path: 'http://jg.dp.fengyan123.com/' },
      ],
      statistics: {},
      currentIndex: 0,
      navId: 168,
      height: '',
      url: {
        list: '/api/kjcy/bBanner/page/admin',
      },
      ipagination: {
        size: 5,
      },
      queryParams: {
        isDisplay: '1',
      },
      WxUrl:
        'https://35dinghuo.oss-cn-hangzhou.aliyuncs.com//_xdfw/2xdfw/openAccountxdfw/2023/07/14xdfw/现代服务首页wnTe.jpg',
      homepageSelection: [], //首页精选数据
      bannerFlag: false,
    }
  },
  async mounted() {
    await this.getChatInfo()
    await this.queryPrimaryClassify()
    await this.getStatisticsInfo()
    await this.mouseMove()
    this.getHomeJXList()
    this.$nextTick((_) => {
      console.log(this.$refs.right.offsetHeight)
      this.height = this.$refs.right.offsetHeight + 'px'
    })
    window.addEventListener('resize', () => {
      this.$nextTick((_) => {
        this.height = this.$refs.right.offsetHeight + 'px'
      })
    })
  },

  methods: {
    ...mapActions(['queryPrimaryClassify', 'getUserInfo']),
    toInfo(item) {
      // console.log(item, 'item')
      // this.$bus.$emit('getWXUrl', item.wxCodeUrl)
      this.$router.push({
        path: '/more/product/info',
        query: {
          id: item.relevanceId || item.id,
        },
      })
    },
    toChat(item) {
      this.$router.push({
        path: '/more/chat',
        query: { serviceId: item.relevanceId || item.id, providerId: item.providerId },
      })
    },
    //发起合作
    async initiateCooperation(item) {
      sessionStorage.setItem('proImg', (item.images && item.images[0].imagesUrl) || '')
      if (this.userType === 'user') {
        this.$refs.cooperation.show({
          ...item,
          price: item?.specs[0].specsBalance,
        })
      } else {
        this.$router.push({
          path: `/login?redirect=${this.$route.fullPath}`,
          replace: true,
        })
      }
    },
    //获取客服信息
    async getChatInfo() {
      let result = await customerInfo({})
      this.chatInfo = {
        qrcode: result.data[0].value,
        phone: result.data[1].value,
        startDay: result.data[2].value.split('_')[0],
        endDay: result.data[2].value.split('_')[1],
        time:
          this.$moment(result.data[3].value.split(',')[0]).format('HH:mm') +
          '-' +
          this.$moment(result.data[3].value.split(',')[2]).format('HH:mm'),
      }
    },
    getHomeJXList() {
      serviceSetListByPage({
        size: 20,
        current: 1,
        model: {
          firstServiceCategoryId: 167,
          type: '2',
          keyword: '',
        },
      }).then((res) => {
        if (res.code == 0) {
          function chunkObjects(objectArray, chunkSize) {
            const result = []
            for (let i = 0; i < objectArray.length; i += chunkSize) {
              result.push(objectArray.slice(i, i + chunkSize))
            }
            return result
          }
          this.homepageSelection = chunkObjects(res.data.records || [], 9)
          this.bannerFlag = true
        }
      })
    },
    isPaused(val) {
      this.show = val
    },
    toProduct() {
      this.$router.push({
        path: '/more/product',
        query: {
          keyword: this.keyword,
        },
      })
    },
    navChange(item, index) {
      this.currentIndex = index
      this.navId = item.id
      // console.log(this.navId)
      this.navClassify.forEach((row) => {
        if (row.id == item.id) {
          this.WxUrl = row.wxCodeUrl
        }
      })
    },
    //监听鼠标和键盘在无操作3分钟后，轮播自动切换
    mouseMove() {
      let timer = null
      document.onmousemove = () => {
        this.show = false
        clearTimeout(timer)
        timer = setTimeout(() => {
          this.show = true
        }, 180000)
      }
      document.onkeydown = () => {
        this.show = false
        clearTimeout(timer)
        timer = setTimeout(() => {
          this.show = true
        }, 180000)
      }
    },
    //获取统计信息
    async getStatisticsInfo() {
      let result = await statisticsInfo()
      this.statistics = result.data
    },
    toGo(item) {
      if (item.name == '服务商入驻') {
        if (JSON.stringify(this.userInfo) == '{}') {
          this.$router.push({
            path: `/login?redirect=${this.$route.fullPath}`,
            replace: true,
          })
        } else {
          this.$router.push({ path: item.path })
        }
      } else if (item.name === '大数据' || item.name === 'erp系统') {
        window.open(item.path)
      } else {
        this.$router.push({ path: item.path })
      }
    },
    //发起咨询
    toWechat() {
      if (this.userType === 'user') {
        this.$router.push({
          path: '/more/chat',
          query: { serviceId: '0', providerId: '1561555533553467392' },
        })
      } else {
        this.$router.push({
          path: `/login?redirect=${this.$route.fullPath}`,
          replace: true,
        })
      }
    },
    toLogin() {
      this.$router.push('/login')
    },
    toPersonal(obj) {
      this.$router.push({
        path: '/more/personalCenter',
        query: obj,
      })
    },
    //登出
    signOut() {
      store.dispatch('LogOut').then(() => {
        this.$router.push({ path: '/home' })
      })
      // location.reload();
    },
    handleClose(done) {
      done()
    },
  },
  watch: {
    show: {
      handler(val) {
        // console.log(val)
        if (val) {
          // this.$refs.mySwiper.autoplay.start()
        } else {
          // this.$refs.mySwiper.autoplay.stop()
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/font/font.css';

.main_content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .noFirst {
    width: 100%;
    height: 100%;
    //background-image: url('@/assets/images/home_bg2.png') !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .mdoe1 {
    width: 100%;
    height: 100%;
    background-image: url('@/assets/images/new_bg.png');
    //background-image: url('@/assets/images/home_bg.gif');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .con_top {
    width: 100%;
    height: 136px;
    //background: rgba(255, 255, 255, 0.04);
    //backdrop-filter: blur(6px);

    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 435px;
      height: 96px;
      background: url('@/assets/images/login_log.png') no-repeat;
      margin-left: 50px;
      cursor: pointer;
      background-size: 100% 100%;
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      margin-right: 60px;

      .right_top {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        margin-bottom: 22px;

        .right_login {
          margin-right: 41px;
          font-size: 18px;
          font-family: 思源黑体;
          letter-spacing: 0px;
          color: #0d5697;
          cursor: pointer;
        }

        .telephone {
          font-family: D-DIN-PRO-Bold-Bold;
          font-size: 22px;
          font-weight: bold;
          letter-spacing: 0px;
          color: #0d5697;
        }
      }

      .right_bottom {
        display: flex;
        align-items: center;
        width: 0.36;
        .server {
          background: #b7f531 !important;
          color: #0d5697 !important;
          border: 1px solid #b7f531 !important;
          width: 158.86px;
          margin-left: 41px;
          cursor: default;
        }
      }

      .search {
        position: relative;
        width: 340px;
      }

      .ser_btn {
        border: 1px solid rgba(255, 255, 255, 0.8);
        width: 100px;
        height: 40px;
        background: rgba(255, 255, 255, 0);
        border-radius: 0 20px 20px 0px;
        color: rgba(255, 255, 255, 0.8);
        position: absolute;
        right: 1px;

        span {
          background: url('@/assets/images/home_search.png') no-repeat;
          display: inline-block;
          padding-left: 20px;
        }
      }

      .searchIpt {
        ::v-deep .el-input__inner {
          border: 1px solid rgba(255, 255, 255, 0.8);
          width: 240px;
          height: 40px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 20px 0 0 20px;
        }
      }

      .login,
      .consult {
        margin-left: 10px;
        border: 1px solid rgba(255, 255, 255, 0.8);
        width: 120px;
        height: 40px;
        background: rgba(255, 255, 255, 0);
        border-radius: 20px;

        color: rgba(255, 255, 255, 0.8);

        span {
          background: url('@/assets/images/home_login.png') no-repeat;
          display: inline-block;
          padding-left: 20px;
        }
      }

      .consult {
        span {
          background: url('@/assets/images/home_cons.png') no-repeat !important;
          padding-left: 25px;
        }
      }

      .person {
        color: #ffffff;
        font-size: 16px;
        line-height: 32px;

        .cover {
          width: 32px;
          height: 32px;
          border-radius: 100px;
          margin-right: 5px;
        }

        ::v-deep .el-icon--right {
          color: #ffffff;
        }

        ::v-deep .el-icon-chat-dot-round,
        ::v-deep .el-icon-document {
          margin-right: 5px;
        }

        .drop {
          padding: 0 15px;
          box-sizing: border-box;
          font-size: 16px;
          cursor: pointer;

          .name {
            color: #ffffff;
          }
        }
      }
    }
  }

  .isHome {
    background: url('@/assets/images/bg.png') no-repeat !important;
    background-size: 100%;
  }

  .home_main {
    height: calc(100% - 236px);
    display: flex;
    padding: 44px 140px 44px 140px;
    box-sizing: border-box;
    width: 100%;

    .left,
    .right {
      width: 12%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .left_item {
        width: 100%;
        height: calc((100% - 300px) / 6);
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0px;
        font-family: 'MyFont';
        font-size: 26px;
        color: #ffffff;
        text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
        transition: all 0.3s;
        cursor: pointer;
        background: url('@/assets/images/textBg.png') no-repeat center;
        background-size: 100% 100%;
        position: relative;

        &:hover {
          background: url('@/assets/images/textBg-active.png') no-repeat center;
          transform-origin: center;
          //font-weight: bold;
          text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
          color: #297cc5;
          background-size: 100% 100%;
        }

        &.active {
          background: url('@/assets/images/textBg-active.png') no-repeat center;
          color: #297cc5;
          background-size: 100% 100%;
          z-index: 1;

          &:after {
            position: absolute;
            content: '';
            background: url('@/assets/images/line.png') no-repeat center;
            width: 50px;
            height: 25px;
            right: -40px;
            z-index: -999;
          }
        }
      }
    }
    .right {
      .left_item {
        &.active {
          &:after {
            position: absolute;
            content: '';
            transform: rotateY(527deg);
            background: url('@/assets/images/line.png') no-repeat center;
            width: 50px;
            height: 25px;
            left: -40px;
            z-index: -999;
          }
        }
      }
    }

    .center {
      margin: 0 1.6%;
      position: relative;
      width: 70%;
      height: 100%;
      box-sizing: border-box;
      .con_content {
        .data_top {
          display: flex;
          margin-bottom: 5px;
          height: 50%;

          .left {
            position: relative;
            width: 38.7%;
            margin-right: 2%;
            flex-shrink: 0;
            cursor: pointer;
            height: 100%;
            overflow: hidden;
            border-radius: 4px;

            .cover {
              width: 100%;
              height: 100%;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .bottom {
              position: absolute;
              top: 0;
              left: 0;
              width: 50%;
              height: 100%;
              //opacity: 0.6;
              z-index: 999;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%);
              padding: 40px 29px;
              box-sizing: border-box;
              transition: all 0.5s;
              transform: translateX(-100%);
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .bottom_title {
                font-family: Alimama ShuHeiTi;
                font-size: 30px;
                font-weight: bold;
                line-height: 36px;
                letter-spacing: 0px;
                color: #ffffff;
                text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
                margin-bottom: 22px;
              }

              .bottom_desc {
                font-family: DMSans-Regular;
                font-size: 14px;
                font-weight: 350;
                //line-height: 36px;
                letter-spacing: 0px;
                opacity: 0.8;
                //min-height: 72px;
                //margin-bottom: 35px;
                color: #ffffff;
              }

              .bottom_btn {
                font-family: Poppins-Medium;
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                letter-spacing: 0px;
                color: @btn-font-color;
              }
            }

            &:hover {
              .bottom {
                transform: translateX(0);
              }
            }
          }

          .right {
            display: flex;
            flex-wrap: wrap;
            width: 60%;

            .data_item {
              width: 31%;
              margin-right: 3.5%;
              //margin-bottom: 20px;
              cursor: pointer;
              height: 100%;
              border-radius: 4px;
              overflow: hidden;

              &:hover {
                .data_title {
                  color: @btn-font-color;
                }
              }

              .cover {
                position: relative !important;
                width: 100%;
                height: 45%;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .data_content {
                padding: 17px 20px;
                box-sizing: border-box;
                background: #ffffff;
                height: calc(100% - 45%);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              }

              .data_con_title {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .data_score {
                  color: #ff8f1f;
                  font-family: Source Han Sans CN;
                  font-size: 14px;
                  font-weight: normal;
                  letter-spacing: 0px;
                  min-width: 33.8px;
                  text-align: right;
                }
              }

              .data_title {
                font-family: Source Han Sans CN;
                font-size: 18px;
                font-weight: bold;
                line-height: 25px;
                letter-spacing: 0px;
                color: #222222;
                text-align: left;
                //margin-bottom: 17px;
              }

              .data_desc {
                font-family: Source Han Sans CN;
                font-size: 14px;
                font-weight: normal;
                line-height: 21px;
                letter-spacing: 0px;
                color: #7b7d8c;
                min-height: 25px;
                //margin-bottom: 17px;
              }

              .data_con_bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;
                //margin-bottom: 18px;

                .data_tag {
                  color: #979797;
                  font-family: Raleway-Regular;
                  font-size: 12px;
                  font-weight: 350;
                  line-height: 17px;
                  letter-spacing: 0px;
                  border-radius: 8px;
                  background: rgba(121, 121, 121, 0.1);
                  width: 68px;
                  height: 22px;
                  text-align: center;
                  line-height: 22px;
                }

                .data_price {
                  color: #979797;
                  font-size: 12px;
                  font-family: Raleway-Regular;
                  font-weight: 350;
                  letter-spacing: 0px;

                  .price {
                    font-family: Source Han Sans CN;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 26px;
                    text-align: right;
                    letter-spacing: 0px;
                    color: #0d5697;
                  }
                }
              }

              .data_btn {
                display: flex;
                width: 100%;

                ::v-deep .el-button {
                  width: 50%;
                  border-radius: 4px;
                  border: 1px solid @btn-font-color;
                  color: @btn-font-color;
                  height: 29px;
                  //line-height: 29px;
                  padding: 4px 20px;
                  font-size: 14px;
                }

                .order {
                  margin-left: 8px;
                  border: 1px solid @btn-font-color;
                  background: @btn-font-color;
                  color: #ffffff;
                }
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .data_bottom {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: 50%;

          .data_item {
            width: 18.4%;
            margin-right: 2%;
            //margin-bottom: 20px;
            cursor: pointer;
            height: 100%;
            border-radius: 4px;
            overflow: hidden;

            &:hover {
              .data_title {
                color: @btn-font-color;
              }
            }

            .cover {
              width: 100%;
              height: 45%;
              overflow: hidden;
              position: relative !important;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .data_content {
              padding: 17px 20px;
              box-sizing: border-box;
              background: #ffffff;
              height: calc(100% - 45%);
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }

            .data_con_title {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .data_score {
                color: #ff8f1f;
                font-family: Source Han Sans CN;
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0px;
                min-width: 33.8px;
                text-align: right;
              }
            }

            .data_title {
              font-family: Source Han Sans CN;
              font-size: 18px;
              font-weight: bold;
              line-height: 25px;
              letter-spacing: 0px;
              color: #222222;
              text-align: left;
              //margin-bottom: 17px;
            }

            .data_desc {
              font-family: Source Han Sans CN;
              font-size: 14px;
              font-weight: normal;
              line-height: 21px;
              letter-spacing: 0px;
              color: #7b7d8c;
              min-height: 25px;
              //margin-bottom: 17px;
            }

            .data_con_bottom {
              display: flex;
              justify-content: space-between;
              align-items: center;
              //margin-bottom: 18px;

              .data_tag {
                color: #979797;
                font-family: Raleway-Regular;
                font-size: 12px;
                font-weight: 350;
                line-height: 17px;
                letter-spacing: 0px;
                border-radius: 8px;
                background: rgba(121, 121, 121, 0.1);
                width: 68px;
                height: 22px;
                text-align: center;
                line-height: 22px;
              }

              .data_price {
                color: #979797;
                font-size: 12px;
                font-family: Raleway-Regular;
                font-weight: 350;
                letter-spacing: 0px;

                .price {
                  font-family: Source Han Sans CN;
                  font-size: 18px;
                  font-weight: bold;
                  line-height: 26px;
                  text-align: right;
                  letter-spacing: 0px;
                  color: #0d5697;
                }
              }
            }

            .data_btn {
              display: flex;
              width: 100%;

              ::v-deep .el-button {
                width: 50%;
                border-radius: 4px;
                border: 1px solid @btn-font-color;
                color: @btn-font-color;
                height: 29px;
                //line-height: 29px;
                padding: 4px 20px;
                font-size: 14px;
              }

              .order {
                margin-left: 8px;
                border: 1px solid @btn-font-color;
                background: @btn-font-color;
                color: #ffffff;
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin-top: 4px;
        background-image: url('@/assets/images/home_center.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 999;
      }

      .first {
        position: relative;
        border-radius: 2px;

        ::v-deep .el-carousel__item {
          border-radius: 2px;
          border: 2px solid rgba(255, 255, 255, 0.5);
        }
      }

      .carousel {
        width: 100%;
        overflow-y: hidden;
      }
    }

    .right {
      z-index: 999;
    }
  }

  .con_bottom {
    width: 100%;
    height: 100px;
    //background: @bg-color;
    box-sizing: border-box;
    padding: 5px 0;
    position: relative;

    .left {
      //margin-left: 40px;
      position: absolute;
      right: 0;
      bottom: 22px;
      color: #fff;
      display: flex;
      align-items: center;

      .ewm {
        margin-right: -20px;
        z-index: 999;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-size: 90%;
        background-position: center;
        background-color: rgba(255, 255, 255, 1);
        background-repeat: no-repeat;
        border: 1px solid rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(10px);
      }

      .text {
        width: 120px;
        height: 40px;
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(255, 255, 255, 0.8);
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(4px);
        padding: 2px;
        border-right: none;
      }
    }
    .right {
      position: absolute;
      width: 200px;
      left: 0 !important;
      position: absolute;
      color: #fff;
      display: flex;
      align-items: center;

      .ewm {
        padding: 5px;
        margin-right: -20px;
        z-index: 999;
        width: 100px;
        height: 100px;
        border-radius: 10%;
        background-size: 90%;
        background-position: center;
        background-color: rgba(255, 255, 255, 1);
        background-repeat: no-repeat;
        border: 1px solid rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(10px);
        position: absolute;
        right: 20px;
      }
      .text {
        width: 120px;
        height: 40px;
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(255, 255, 255, 0.8);
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(4px);
        padding: 2px;
        border-left: none;
      }
    }

    .bottom_center {
      width: 830px;
      height: 90px;
      position: absolute;
      left: 50%;
      margin-left: -415px;
      top: 5px;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }

    .bot_top {
      display: flex;

      div {
        width: 95px;
        text-align: center;
        border-right: 1px solid #fff;
        padding-bottom: 2px;
        cursor: pointer;
      }

      .no_boder {
        border: none;
      }

      margin: 25px 0 10px 0;
    }

    .bot_bot {
      font-size: 12px;
      cursor: pointer;

      a {
        color: #ffffff;

        //&:hover {
        //  color: @btn-font-color;
        //}
      }
    }
  }
}

::v-deep .el-input__inner {
  color: #ffffff !important;
}

::v-deep .el-carousel__arrow {
  background: rgba(31, 45, 61, 0.7);
}

.kfDialog {
  text-align: center;

  div {
    color: #202020;
  }

  p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
</style>
