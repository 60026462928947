import { axios } from '@/utils/request'

//今日动态
export function todayNews(data = {}) {
  return axios({
    url: `/api/kjcy/bService/today/info`,
    method: 'post',
    data
  })
}

//新闻动态
export function news(data = {}) {
  return axios({
    url: `/api/kjcy/bArticle/page`,
    method: 'post',
    data
  })
}

//新增需求
export function demand(data = {}) {
  return axios({
    url: `/api/kjcy/bDemand`,
    method: 'post',
    data
  })
}

//需求列表
export function demandList(data = {}) {
  return axios({
    url: `/api/kjcy/bDemand/pageAll/home`,
    method: 'post',
    data
  })
}
//需求编辑
export function demandEdit(data = {}) {
  return axios({
    url: `/api/kjcy/bDemand`,
    method: 'put',
    data
  })
}
//需求复制
export function demandDelete(data = {}) {
  return axios({
    url: `/api/kjcy/bDemand`,
    method: 'delete',
    data
  })
}
//需求详情


//跟踪
export function track(data) {
  return axios({
    url: `/api/kjcy/bDemandUser/save`,
    method: 'post',
    data
  })
}

//评论列表
export function carousel(data) {
  return axios({
    url: `/api/kjcy/bBanner/page`,
    method: 'post',
    data
  })
}

export function addComment(data) {
  return axios({
    url: `/api/kjcy/bArticleComment/create`,
    method: 'post',
    data
  })
}
export function bServiceCategoryRelevance(serviceCategoryId) {
  return axios({
    url: `/api/kjcy/bServiceCategoryRelevance/getServiceCategoryRelevanceByServiceCategoryId/${serviceCategoryId}`,
    method: 'get'
  })
}
