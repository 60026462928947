<template>
  <div class="synamic">
    <div class="left" v-if="dataSource&&dataSource.length">
      <div v-if="dataSource[0]" class="content" @click="toInfo(dataSource[0])">
        <div class="cover">
          <img :src="dataSource[0].frontCoverFile" alt="">
        </div>
        <div class="con_left">
          <div class="title">平台动态</div>
          <div class="flex">
            <div class="date">{{ $moment(dataSource[0].displayTime||dataSource[0].createTime).format('DD') }}</div>
            <div class="year">{{ $moment(dataSource[0].displayTime||dataSource[0].createTime).format('YYYY/MM') }}</div>
          </div>
          <div class="desc">{{ dataSource[0].description }}</div>
          <div class="btn">查看详情<i style="margin-left: 10px" class="el-icon-right"></i></div>
        </div>

      </div>
    </div>
    <div class="right"  ref="right">
      <div class="nav flex space-between">
        <div class="flex" style="flex: 1">
          <div class="nav_item hvr-radial-out" v-for="(item, index) in navList.slice(0,3)" :key="index" @click="navChange(item,index)"
               :class="{ isActive: currentIndex==index }">{{ item.relevanceName }}
          </div>
        </div>
        <div @click="toList" class="more">查看全部 ></div>
      </div>
      <div class="right_content">
                <vue-seamless-scroll :data="dataSource"
                                     :class-option="defaultOption" style="overflow: hidden;" :style="{height:height}">
                  <div class="r_item" v-for="(item,index) in dataSource" :key="index"
                       @click="toInfo(item)">
                    <div class="time">
                      <span class="date">{{ $moment(item.displayTime||item.createTime).format('DD')}}</span>
                      <span class="year">{{ $moment(item.displayTime||item.createTime).format('YYYY/MM')}}</span>
                    </div>
                    <div class="title no_two_wrap">{{ item.title }}</div>
                    <div class="desc no_one_wrap">
                      {{ item.description }}
                    </div>
                    <el-divider/>
                  </div>
                </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import {bServiceCategoryRelevance} from "@/api/home";
import tableList from "@/mixins/tableList";

export default {
  mixins: [tableList],
  data() {
    return {
      navList: [],
      currentIndex: 0,
      disabledLoadData: true,
      url: {
        list: '/api/kjcy/bArticle/bArticleListByPage/admin'
      },
      height:''
    }
  },
  mounted() {
    this.getList()
    this.$nextTick(_=>{
      this.height=this.$refs.right.offsetHeight-60-27-27+'px'
    })
  },
  computed: {
    defaultOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: this.dataSource.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  methods: {
    async getList() {
      let {data} = await bServiceCategoryRelevance(180)
      this.navList = data
      this.queryParams.type = this.navList[this.currentIndex].type
      this.loadData()
    },
    toList() {
      this.$router.push('/more/list')
    },
    navChange(item, index) {
      this.currentIndex = index
      this.queryParams.type = item.type
      this.loadData()
    },
    toInfo(item) {
      this.$router.push({
        path: '/more/list/info',
        query: {
          id: item.id
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.synamic {
  margin-top: 20px;
  height: calc(100% - 50px);
  display: flex;
  img{
    background: transparent;
    object-fit: fill;
  }

  .left {
    width: 65%;
    //margin-right: 2%;
    height: 100%;
    border-radius: 4px;

    .content {
      width: 100%;
      height: 100%;
      position: relative;
      border-radius: 4px;
      cursor: pointer;
      overflow: hidden;

      .cover {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 4px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .con_left {
        width: 50%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.2) 100%);
        border-radius: 0px 0px 4px 4px;
        z-index: 999;
        padding: 120px 44px;
        box-sizing: border-box;
        transition: all 0.5s;
        transform: translateX(-100%);


        .title {
          text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
          color: #FFFFFF;
          letter-spacing: 0px;
          line-height: 36px;
          font-weight: bold;
          font-size: 36px;
          font-family: Alimama ShuHeiTi;
          text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
          margin-bottom: 40px;
        }

        .date {
          letter-spacing: 1.05px;
          text-align: center;
          line-height: 30px;
          font-weight: bold;
          font-family: RobotoRoman-SemiBold;
          font-size: 36px;
          color: #FFFFFF;
        }

        .year {
          font-family: RobotoRoman-SemiBold;
          font-size: 14px;
          font-weight: 350;
          line-height: 21px;
          text-align: center;
          letter-spacing: 1.05px;
          color: #FFFFFF;
          margin-top: 12px;
          margin-left: 10px;
        }

        .desc {
          margin-top: 29px;
          margin-bottom: 40px;
          opacity: 0.8;
          font-family: DMSans-Regular;
          font-size: 18px;
          font-weight: 350;
          line-height: 40px;
          letter-spacing: 0px;
          color: #FFFFFF;
          //min-height: 201px;
        }

        .btn {
          color: #FCD980;
          font-family: Poppins-Medium;
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0px;
        }
      }
      &:hover{
        .con_left{

          transform: translateX(0);
        }
      }
    }
  }

  .right {
    width: 35%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    .nav{
      height: 60px;
      line-height: 60px;

      .nav_item{
        height: 100%;
        width: 36.27%;
        text-align: center;
        color: #777181;
        font-family: MicrosoftYaHei;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0em;
        cursor: pointer;
        &.isActive{
          background: rgba(213,97,46,0.2)!important;
          color: @btn-font-color;
          box-shadow: 0px 40px 80px 0px rgba(223, 223, 223, 0.25);
        }
      }
      .more{
        color: #777181;
        font-size: 14px;
        font-family: HarmonyOS_Sans_SC_Medium;
        padding: 0 16px;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
    .right_content{
      padding: 27px 30px;
      box-sizing: border-box;
      .r_item{

        &:hover{
          cursor: pointer;
          .title{
            color: @btn-font-color;

          }

        }
      }
      .date{
        color: #777181;
        font-family: RobotoRoman-SemiBold;
        font-size: 24px;
        font-weight: bold;
        line-height: 21px;
        text-align: center;
        letter-spacing: 1.05px;
        margin-right: 10px;
      }
      .year{
        color: #777181;
        font-family: RobotoRoman-SemiBold;
        font-size: 14px;
        font-weight: 350;
        line-height: 21px;
        text-align: center;
        letter-spacing: 1.05px;
      }
      .title{
        margin: 8px 0;
        font-family: Source Han Sans CN;
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        letter-spacing: 0.5px;
        color: #050510;

      }
      .desc{
        font-family: Source Han Sans CN;
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #777181;
        text-indent: 2em;
      }
    }
  }
  ::v-deep .el-divider--horizontal{
    margin: 20px 0;
  }
}
</style>
