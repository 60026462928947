var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"construction"},[_c('div',{staticClass:"con_header"},[_c('div',{staticClass:"left"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,staticClass:"left_item",class:{ active: _vm.currentIndex == index },on:{"click":function($event){return _vm.handleClick(index, item)}}},[_c('div',{staticClass:"left_name"},[_vm._v(_vm._s(item.categoryName))]),_c('el-divider',{attrs:{"direction":"vertical"}})],1)}),0),_c('div',{staticClass:"more",on:{"click":_vm.cliMore}},[_vm._v("更多产品>>")])]),_c('div',{staticClass:"con_content"},[(_vm.dataSource && _vm.currentIndex != 0)?_c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"data_top"},[(_vm.dataSource.length)?_c('div',{staticClass:"left",on:{"click":function($event){return _vm.toInfo(_vm.dataSource[0])}}},[_c('div',{staticClass:"cover"},[_c('img',{staticClass:"hvr-grow",attrs:{"src":_vm.dataSource[0].echoMap && _vm.dataSource[0].echoMap.images && _vm.dataSource[0].echoMap.images.length
                  ? _vm.dataSource[0].echoMap.images[0].imagesUrl
                  : require('@/assets/images/empty.png')}})]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"flex space-between"},[_c('div',{staticClass:"bottom_title no_two_wrap"},[_vm._v(_vm._s(_vm.dataSource[0].serviceName))]),_c('div',{staticClass:"data_score"},[_vm._v(" "+_vm._s(_vm.dataSource[0].serviceScore ? _vm.dataSource[0].serviceScore + '分' : '5.0分')+" ")])]),_c('div',{staticClass:"bottom_desc no_one_wrap"},[_vm._v(_vm._s(_vm.dataSource[0].serviceTitle))]),_c('div',{staticClass:"data_con_bottom",staticStyle:{"margin":"20px 0"}},[_c('div',{staticClass:"data_tag"},[_vm._v("平台优选")]),_c('div',{staticClass:"data_price"},[_c('span',[_vm._v("价格：")]),_c('span',{staticClass:"price"},[_vm._v("￥"+_vm._s(_vm.dataSource[0].serviceSpecsType == '0' && _vm.dataSource[0].echoMap.specs[0].specsBalance ? _vm.dataSource[0].echoMap.specs[0].specsBalance : '面议'))])])]),_c('div',{staticClass:"data_btn"},[_c('el-button',{staticClass:"hvr-shadow",on:{"click":function($event){$event.stopPropagation();return _vm.toChat(_vm.dataSource[0])}}},[_vm._v("咨询")]),_c('el-button',{staticClass:"order hvr-shadow",on:{"click":function($event){$event.stopPropagation();return _vm.initiateCooperation(_vm.dataSource[0])}}},[_vm._v("下单")])],1)])]):_vm._e(),_c('div',{staticClass:"right"},_vm._l((_vm.dataSource.slice(1, 4)),function(item,index){return _c('div',{key:index,staticClass:"data_item",on:{"click":function($event){return _vm.toInfo(item)}}},[_c('div',{staticClass:"cover"},[_c('img',{staticClass:"hvr-grow",attrs:{"src":item.echoMap && item.echoMap.images && item.echoMap.images.length
                    ? item.echoMap.images[0].imagesUrl
                    : require('@/assets/images/empty.png')}})]),_c('div',{staticClass:"data_content"},[_c('div',{staticClass:"data_con_title"},[_c('div',{staticClass:"data_title no_one_wrap"},[_vm._v(_vm._s(item.serviceName))]),_c('div',{staticClass:"data_score"},[_vm._v(_vm._s(item.serviceScore ? item.serviceScore + '分' : '5.0分'))])]),_c('div',{staticClass:"data_desc no_one_wrap"},[_vm._v(" "+_vm._s(item.serviceTitle)+" ")]),_c('div',{staticClass:"data_con_bottom"},[_c('div',{staticClass:"data_tag"},[_vm._v("平台优选")]),_c('div',{staticClass:"data_price"},[_c('span',[_vm._v("价格：")]),_c('span',{staticClass:"price"},[_vm._v("￥"+_vm._s(item.serviceSpecsType == '0' && item.echoMap.specs[0].specsBalance ? item.echoMap.specs[0].specsBalance : '面议'))])])]),_c('div',{staticClass:"data_btn"},[_c('el-button',{staticClass:"hvr-shadow",on:{"click":function($event){$event.stopPropagation();return _vm.toChat(item)}}},[_vm._v("咨询")]),_c('el-button',{staticClass:"order hvr-shadow",on:{"click":function($event){$event.stopPropagation();return _vm.initiateCooperation(item)}}},[_vm._v("下单")])],1)])])}),0)]),_c('div',{staticClass:"data_bottom"},_vm._l((_vm.dataSource.slice(4, 9)),function(item,index){return _c('div',{key:index,staticClass:"data_item",on:{"click":function($event){return _vm.toInfo(item)}}},[_c('div',{staticClass:"cover"},[_c('img',{staticClass:"hvr-grow",attrs:{"src":item.echoMap && item.echoMap.images && item.echoMap.images.length
                  ? item.echoMap.images[0].imagesUrl
                  : require('@/assets/images/empty.png')}})]),_c('div',{staticClass:"data_content"},[_c('div',{staticClass:"data_con_title"},[_c('div',{staticClass:"data_title no_one_wrap"},[_vm._v(_vm._s(item.serviceName))]),_c('div',{staticClass:"data_score"},[_vm._v(_vm._s(item.serviceScore ? item.serviceScore + '分' : '5.0分'))])]),_c('div',{staticClass:"data_desc no_one_wrap"},[_vm._v(" "+_vm._s(item.serviceTitle)+" ")]),_c('div',{staticClass:"data_con_bottom"},[_c('div',{staticClass:"data_tag"},[_vm._v("平台优选")]),_c('div',{staticClass:"data_price"},[_c('span',[_vm._v("价格：")]),_c('span',{staticClass:"price"},[_vm._v("￥"+_vm._s(item.serviceSpecsType == '0' && item.echoMap.specs[0].specsBalance ? item.echoMap.specs[0].specsBalance : '面议'))])])]),_c('div',{staticClass:"data_btn"},[_c('el-button',{staticClass:"hvr-shadow",on:{"click":function($event){$event.stopPropagation();return _vm.toChat(item)}}},[_vm._v("咨询")]),_c('el-button',{staticClass:"order hvr-shadow",on:{"click":function($event){$event.stopPropagation();return _vm.initiateCooperation(item)}}},[_vm._v("下单")])],1)])])}),0)]):_vm._e(),(_vm.dataList && _vm.currentIndex == 0)?_c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"data_top"},[(_vm.dataList.length)?_c('div',{staticClass:"left",on:{"click":function($event){return _vm.toInfo(_vm.dataList[0])}}},[_c('div',{staticClass:"cover"},[_c('img',{staticClass:"hvr-grow",attrs:{"src":_vm.dataList[0].echoMap && _vm.dataList[0].echoMap.images && _vm.dataList[0].echoMap.images.length
                  ? _vm.dataList[0].echoMap.images[0].imagesUrl
                  : require('@/assets/images/empty.png')}})]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"flex space-between"},[_c('div',{staticClass:"bottom_title no_two_wrap"},[_vm._v(_vm._s(_vm.dataList[0].relevanceName))]),_c('div',{staticClass:"data_score"},[_vm._v(_vm._s(_vm.dataList[0].serviceScore ? _vm.dataList[0].serviceScore + '分' : '5.0分'))])]),_c('div',{staticClass:"bottom_desc no_one_wrap"},[_vm._v(_vm._s(_vm.dataList[0].relevanceTitle))]),_c('div',{staticClass:"data_con_bottom",staticStyle:{"margin":"20px 0"}},[_c('div',{staticClass:"data_tag"},[_vm._v("平台优选")]),_c('div',{staticClass:"data_price"},[_c('span',[_vm._v("价格：")]),_c('span',{staticClass:"price"},[_vm._v("￥"+_vm._s(_vm.dataList[0].serviceSpecsType == '0' && _vm.dataList[0].echoMap.specs[0].specsBalance ? _vm.dataList[0].echoMap.specs[0].specsBalance : '面议'))])])]),_c('div',{staticClass:"data_btn"},[_c('el-button',{staticClass:"hvr-shadow",on:{"click":function($event){$event.stopPropagation();return _vm.toChat(_vm.dataList[0])}}},[_vm._v("咨询")]),_c('el-button',{staticClass:"order hvr-shadow",on:{"click":function($event){$event.stopPropagation();return _vm.initiateCooperation(_vm.dataList[0])}}},[_vm._v("下单")])],1)])]):_vm._e(),_c('div',{staticClass:"right"},_vm._l((_vm.dataList.slice(1, 4)),function(item,index){return _c('div',{key:index,staticClass:"data_item",on:{"click":function($event){return _vm.toInfo(item)}}},[_c('div',{staticClass:"cover"},[_c('img',{staticClass:"hvr-grow",attrs:{"src":item.echoMap && item.echoMap.images && item.echoMap.images.length
                    ? item.echoMap.images[0].imagesUrl
                    : require('@/assets/images/empty.png')}})]),_c('div',{staticClass:"data_content"},[_c('div',{staticClass:"data_con_title"},[_c('div',{staticClass:"data_title no_one_wrap"},[_vm._v(_vm._s(item.relevanceName))]),_c('div',{staticClass:"data_score"},[_vm._v(_vm._s(item.serviceScore ? item.serviceScore + '分' : '5.0分'))])]),_c('div',{staticClass:"data_desc no_one_wrap"},[_vm._v(" "+_vm._s(item.relevanceTitle)+" ")]),_c('div',{staticClass:"data_con_bottom"},[_c('div',{staticClass:"data_tag"},[_vm._v("平台优选")]),_c('div',{staticClass:"data_price"},[_c('span',[_vm._v("价格：")]),_c('span',{staticClass:"price"},[_vm._v("￥"+_vm._s(item.serviceSpecsType == '0' && item.echoMap.specs[0].specsBalance ? item.echoMap.specs[0].specsBalance : '面议'))])])]),_c('div',{staticClass:"data_btn"},[_c('el-button',{staticClass:"hvr-shadow",on:{"click":function($event){$event.stopPropagation();return _vm.toChat(item)}}},[_vm._v("咨询")]),_c('el-button',{staticClass:"order hvr-shadow",on:{"click":function($event){$event.stopPropagation();return _vm.initiateCooperation(item)}}},[_vm._v("下单")])],1)])])}),0)]),_c('div',{staticClass:"data_bottom"},_vm._l((_vm.dataList.slice(4, 9)),function(item,index){return _c('div',{key:index,staticClass:"data_item",on:{"click":function($event){return _vm.toInfo(item)}}},[_c('div',{staticClass:"cover"},[_c('img',{staticClass:"hvr-grow",attrs:{"src":item.echoMap && item.echoMap.images && item.echoMap.images.length
                  ? item.echoMap.images[0].imagesUrl
                  : require('@/assets/images/empty.png')}})]),_c('div',{staticClass:"data_content"},[_c('div',{staticClass:"data_con_title"},[_c('div',{staticClass:"data_title no_one_wrap"},[_vm._v(_vm._s(item.relevanceName))]),_c('div',{staticClass:"data_score"},[_vm._v(_vm._s(item.serviceScore ? item.serviceScore + '分' : '5.0分'))])]),_c('div',{staticClass:"data_desc no_one_wrap"},[_vm._v(" "+_vm._s(item.relevanceTitle)+" ")]),_c('div',{staticClass:"data_con_bottom"},[_c('div',{staticClass:"data_tag"},[_vm._v("平台优选")]),_c('div',{staticClass:"data_price"},[_c('span',[_vm._v("价格：")]),_c('span',{staticClass:"price"},[_vm._v("￥"+_vm._s(item.serviceSpecsType == '0' && item.echoMap.specs[0].specsBalance ? item.echoMap.specs[0].specsBalance : '面议'))])])]),_c('div',{staticClass:"data_btn"},[_c('el-button',{staticClass:"hvr-shadow",on:{"click":function($event){$event.stopPropagation();return _vm.toChat(item)}}},[_vm._v("咨询")]),_c('el-button',{staticClass:"order hvr-shadow",on:{"click":function($event){$event.stopPropagation();return _vm.initiateCooperation(item)}}},[_vm._v("下单")])],1)])])}),0)]):_vm._e()]),_c('Cooperation',{ref:"cooperation"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }