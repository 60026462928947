<template>
  <div ref="playerBox" :style="{height:height}" style="position: relative">
    <div :id="id" ref="player" class="flex-1 dplayer" :style="{height:height}" ></div>
    <div class="pause-triangle" v-show="isPaused" @click="changePaused"></div>

  </div>
</template>

<script>
import DPlayer from 'dplayer'

export default {
  name: 'player',
  props: {
    height: {
      type: String,
      default: '638px'
    },
    imageUrl:{
      type: String,
      default: ''
    },
    id:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dp: null,
      volumeStatus: true,
      isPaused:true
    }
  },
  mounted() {

    let options = {
      container: document.getElementById(this.id),
      video: {
        url: this.imageUrl,
        pic: 'https://i-1-lanrentuku.52tup.com/2020/12/29/20d1e770-88b4-4574-867f-a3bddc433e9d.jpg'
      },
      autoplay: false,
      theme: '#b7daff', // 风格颜色，例如播放条，音量条的颜色
      loop: true, // 是否自动循环
      lang: 'zh-cn', // 语言，'en', 'zh-cn', 'zh-tw'
      screenshot: false, // 是否允许截图（按钮），点击可以自动将截图下载到本地
      hotkey: false, // 是否支持热键，调节音量，播放，暂停等
      preload: 'none', // 自动预加载
      muted: false,
      aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
      fluid: true, //当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
      logo: require('@/assets/images/login_log.png') // 在视频左脚上打一个logo
    }
    this.dp = new DPlayer(options)
    this.dp.on('canplay', () => {
      if (this.volumeStatus) {
        this.dp.volume(0)
        this.volumeStatus = false
      }
      this.dp.play()
    })
    this.isPaused=this.dp.video.paused

    // console.log(this.dp.video.paused)
    this.$emit('isPaused',this.dp.video.paused)
  },
  activated() {
    this.dp.play()
  },
  methods: {
    changePaused(){
      this.isPaused=!this.isPaused
      this.dp.play()
    }
  },
  watch:{
    dp:{
      handler(oldValue,newValue){
        if(oldValue&&newValue){
          this.isPaused=oldValue.video.paused

          // console.log(oldValue.video.paused,newValue.video.paused)
          this.$emit('isPaused',oldValue.video.paused)

        }
      },
      deep:true,
      immediate:true
    }
  }

}
</script>

<style scoped lang="less">
.dplayer {
  ::v-deep .dplayer-video-wrap {
    min-height: 428px;
    min-width: 780px;
    width: 100%;
  }
  ::v-deep .dplayer-video {
    object-fit: fill;
  }

  ::v-deep .dplayer-logo {
    max-width: 300px;
  }
}
.pause-triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 添加样式以显示一个三角形，例如一个带有边框的箭头 */
  width: 50px;
  height: 50px;
  background: url("@/assets/images/暂停.png") no-repeat center;
  background-size: cover;
  cursor: pointer;
}
</style>
