import { render, staticRenderFns } from "./Construction.vue?vue&type=template&id=5f3e1861&scoped=true&"
import script from "./Construction.vue?vue&type=script&lang=js&"
export * from "./Construction.vue?vue&type=script&lang=js&"
import style0 from "./Construction.vue?vue&type=style&index=0&id=5f3e1861&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f3e1861",
  null
  
)

export default component.exports