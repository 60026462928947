import axios from 'axios'
import store from '@/store'
import { VueAxios } from './axios'
import { Message } from 'element-ui'
import { getUserTokenKey, getVisitorTokenKey } from '@/utils/auth'
import { tansParams } from '@/utils/common'
// import { showLoading, hideLoading } from '@/utils/loading'

/**
 * 【指定 axios的 baseURL】
 * 则映射后端域名，通过 vue.config.js
 * @type {*|string}
 */
// let apiBaseUrl = process.env.VUE_APP_BASE_API

let apiBaseUrl = 'https://jg.mini.fengyan123.com/'
// let apiBaseUrl = 'http://192.168.0.103:8940/'

// 创建 axios 实例
const service = axios.create({
  baseURL: apiBaseUrl, // api base_url
  timeout: 60000, // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] =
      getUserTokenKey() || getVisitorTokenKey() ? 'Basic ' + (getUserTokenKey() || getVisitorTokenKey() || '') : ''
    config.headers['token'] =
      getUserTokenKey() || getVisitorTokenKey() ? 'Bearer ' + (getUserTokenKey() || getVisitorTokenKey() || '') : ''
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params)
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    return config
  },
  (error) => {
    // hideLoading()
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // hideLoading()
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    const msg = res.data.msg
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res
    }
    if (code === 401 || code === 40009) {
      store.dispatch('getVisitorToken').then(() => {
        location.href = '/'
      })
    } else if (code === 500) {
      Message({
        message: msg,
        type: 'error',
      })
      return Promise.reject('error')
    } else if (code !== 200) {
      Message({
        message: msg,
        type: 'error',
      })
      return Promise.reject('error')
    } else if (code < 0) {
      Message({
        message: msg,
        type: 'error',
      })
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  (error) => {
    // hideLoading()
    console.log('err' + error)
    let { message } = error
    if (message === 'Network Error') {
      message = '后端接口连接异常'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000,
    })
    return Promise.reject(error)
  }
)

const installer = {
  vm: {},
  install(Vue, router = {}) {
    Vue.use(VueAxios, router, service)
  },
}

export { installer as VueAxios, service as axios }
