if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
        .register('/sw.js')
        .then(registration => {
          console.log('Service Worker registered:', registration);
        })
        .catch(error => {
          console.log('Service Worker registration failed:', error);
        });
  });
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Storage from 'vue-ls'
import md5 from 'js-md5'
import moment from 'moment'
import Pagination from '@/components/Pagination/Pagination.vue'
import './plugins/element'
import './publicStyle/commonCss.less' //公共样式
import './publicStyle/default.less' //重置样式
import 'hover.css'
// import 'lib-flexible/flexible'
// import '@/utils/rem'
// import 'element-ui/lib/theme-chalk/index.css'
import './theme/index.css'
import './permission'
import './utils/filter'
import plugins from './plugins' // plugins
import { math } from '@/utils/math.js'
import config from '@/defaultSettings'
import 'animate.css'
// Vue.use(animated)
Vue.prototype.$md5 = md5
Vue.prototype.$moment = moment
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
Vue.use(Storage, config.storageOptions)
Vue.use(plugins)
Vue.prototype.$math = math
Vue.use(Pagination)
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
